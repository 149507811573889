import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PagesModule } from './Pages/Pages.module';
import { FooterComponent } from './Layout/footer/footer.component';
import { HeaderComponent } from './Layout/header/header.component';
import { LayoutComponent } from './Layout/Layout/Layout.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgwWowModule } from 'src/shared/components/ngx-wow/wow.module';



@NgModule({
  declarations: [
    AppComponent, FooterComponent, HeaderComponent, LayoutComponent
   ],
  imports: [
    BrowserModule, BrowserAnimationsModule, NgwWowModule , RouterModule,FontAwesomeModule,
    AppRoutingModule, PagesModule
  ],
  providers: [{provide:LocationStrategy ,useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
