<!--========================================================
														FOOTER 
	=========================================================-->
	<footer id="footer">
		<div class="container">
			<div class="hline v2"></div>
			<div class="row">
				<div class="grid_12">

					<h1><a [routerLink]="[ '/home' ]">Alpha Witch</a> </h1>
					<p class="txt_priv wow fadeInLeft">&copy; <span id="copyright-year"></span>. <a [routerLink]="[ '/privacy' ]">Privacy Policy</a><br></p>



					<ul class="soc_icons2 wow fadeInRight">
						<li><a href="https://www.facebook.com/alphawitchpub" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a></li>
						<li><a href="https://twitter.com/AlphaWitchPub" target="_blank"><fa-icon [icon]="faTwitter"></fa-icon></a></li>
						<li><a href="https://www.pinterest.com/alphawitchp/" target="_blank"><fa-icon [icon]="faPinterest"></fa-icon></a></li>
						<li><a href="https://www.linkedin.com/in/AlphaWitchPub" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
						</li>
						<!-- <li><a href="https://www.pinterest.com/alphawitchp/feed.rss" target="_blank"><i class="fa fa-rss"></i></a>
						</li> -->
						<li><a href="https://blog.alphawitch.com/" target="_blank"><fa-icon [icon]="faNewspaper"></fa-icon></a></li>
					</ul>

				</div>
			</div>
		</div>
	</footer>
	<a href="#header" id="toTop" class="fa fa-chevron-up" style="display: inline;" appAnchorScroll></a>