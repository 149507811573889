import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgwWowService } from 'ngx-wow';

import { filter } from 'rxjs/operators';
declare var $ :any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Alpha Witch Publishing';
  private settings: any = {
    text: '',
    min: 500,
    scrollSpeed: 800,
    containerID: 'toTop',
    containerClass: 'fa fa-chevron-up',
    easingType: 'linear'

  };

  constructor(private router: Router, private wowService: NgwWowService, private elemRender: Renderer2) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // Reload WoW animations when done navigating to page,
      // but you are free to call it whenever/wherever you like
      this.wowService.init();
    });
  }

  ngOnInit(): void {

    this.UItoTop();

  }

  public UItoTop() {
    $('#toTop').hide().click(function () {
      $('html, body').stop().animate({ scrollTop: 0 }, this.settings.scrollSpeed, this.settings.easingType);
      $('#' + this.settings.containerHoverID, this).stop().animate({ opacity: 0 }, this.settings.inDelay, this.settings.easingType);
      return false;
    });
  }

  @HostListener('window:scroll', ['$event']) OnWindowScroll($event) {
    const uptop = document.querySelector('#toTop');
    const sd = window.pageYOffset;
    if (sd > this.settings.min) {
      $(uptop).stop(true, true).fadeIn(600);
    }
    else {
      $(uptop).fadeOut(800);
    }
  }

  ngOnDestroy(): void {

  }
}
